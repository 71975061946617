<template>
  <a-drawer width="50%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="giftName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播礼物.礼物名称')}}<template slot="title">{{$t('直播礼物.礼物名称')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.giftName" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('直播礼物.礼物名称')" />
      </a-form-model-item>

      <a-form-model-item :label="$t('礼物价格')" prop="giftPrice">
        <a-input v-model="form.giftPrice" @change="limitPrice()" suffix="¥"
                 :placeholder="$t('通用.输入.请输入')+$t('礼物价格')"/>
      </a-form-model-item>

<!--      <a-form-model-item prop="picture" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('直播礼物.图片')}}<template slot="title">{{$t('直播礼物.图片')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <file-upload v-model="form.picture" type="image"></file-upload>-->
<!--      </a-form-model-item>-->

        <a-form-model-item :label="this.$t('直播礼物.图片')" prop="picture">
            <a-upload
                    name="picture"
                    listType="picture-card"
                    :multiple="false"
                    :show-upload-list="false"
                    :preview="handlePreview"
                    :before-upload="beforeOssUpload"
                    :customRequest="coverImgUpload">
                <img
                        v-if="form.picture"
                        :src="form.picture"
                        alt="picture"
                        style="height: 100px; width: 100px;"/>
                <div v-else>
                    <a-icon :type="loading ? 'loading' : 'plus'"/>
                    <div class="ant-upload-text">{{ $t('通用.按钮.上传') }}</div>
                </div>
            </a-upload>
            <span>推荐尺寸 100 x 100</span>
        </a-form-model-item>








<!--      <a-form-model-item prop="svgaurl" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('直播礼物.特效')}}<template slot="title">{{$t('直播礼物.特效')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.svgaurl" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('直播礼物.特效')" />-->
<!--      </a-form-model-item>-->

      <a-form-model-item prop="sort" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播礼物.排序')}}<template slot="title">{{$t('直播礼物.排序')}}</template>
          </a-tooltip>
        </span>
        <a-input-number v-model="form.sort"  :placeholder="$t('通用.输入.请输入')+$t('直播礼物.排序')" />
      </a-form-model-item>
      <a-form-model-item prop="status" >
        <span slot="label" >
          <a-tooltip>
            {{$t('是否有效')}}<template slot="title">{{$t('是否有效')}}</template>
          </a-tooltip>
        </span>
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="d.value">{{ d.label }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
<!--      <a-form-model-item prop="remark" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('直播礼物.备注')}}<template slot="title">{{$t('直播礼物.备注')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.remark" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getGift, addGift, updateGift } from '@/api/live/gift'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject, uploadOss} from "@/api/tool/oss";


export default {
  name: 'CreateForm',

  components: {
    CustomDictTag,
  },
  data () {
    return {
        statusOptions:[
            {label:"是",value:1},
            {label:"否",value:0}
        ],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        giftName: null,

        giftPrice: null,

        picture: null,

        svgaurl: null,

        sort: null,

        status: 0,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        giftName: [{ required: true, message: '请输入礼物名称', trigger: 'blur' }],
        giftPrice: [{ required: true, message: '礼物价格', trigger: 'blur' }],
        picture: [{ required: true, message: '请输入经验值', trigger: 'blur' }],
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {

      // oss 上传开始
      async handlePreview(file) {
          let suffix = file.name.substring(file.name.lastIndexOf('.'));
          if (suffix === '.mp4') {
              this.handleVideoPreview(file);
          } else {
              this.handleImagePreview(file);
          }
      },

      async handleVideoPreview(file) {
          if (!file.url && !file.preview) {
              file.preview = await this.getBase64(file.originFileObj)
          }
          this.previewUrl = file.url || file.preview
          this.previewVideoVisible = true
      },
      async handleImagePreview(file) {
          if (!file.url && !file.preview) {
              file.preview = await this.getBase64(file.originFileObj)
          }
          this.previewUrl = file.url || file.preview
          this.previewImageVisible = true
      },
      getBase64(file) {
          return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.readAsDataURL(file)
              reader.onload = () => resolve(reader.result)
              reader.onerror = error => reject(error)
          })
      },
      // 上传之前校验
      beforeOssUpload(file) {
          // const isJPG = file.type === 'image/jpeg';
          const isLt2M = file.size / 1024 / 1024 < 20
          // if (!isJPG) {
          //     this.$message.error('上传头像图片只能是 JPG 格式!');
          // }
          if (!isLt2M) {
              this.$message.error('上传图片大小不能超过 20MB!')
          }
          return isLt2M
      },
      // 上传覆盖默认的上传行为
      coverImgUpload(file, index) {
          const _this = this
          // 获取文件对象
          const uploadFile = file.file
          // 文件夹目录
          const prefix = 'banner'
          const url = uploadOss(uploadFile.name, prefix)
          _this.$nextTick(() => {
              uploadObject(url, uploadFile).then(res => {
                  _this.$set(_this.form, 'picture', res)
                  _this.$message.success(this.$t('通用.文本.上传成功'),)
              })
          })
      },
      coverDetailImgUpload(file, index) {
          const _this = this
          // 获取文件对象
          const uploadFile = file.file
          // 文件夹目录
          const prefix = 'live'
          const url = uploadOss(uploadFile.name, prefix)
          _this.$nextTick(() => {
              uploadObject(url, uploadFile).then(res => {
                  _this.$set(_this.form, 'detailImg', res)
                  _this.$message.success(this.$t('通用.文本.上传成功'),)
              })
          })
      },
      // oss 上传结束



    limitPrice() {
      let val = this.form.giftPrice.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val<0) {
        val = 0
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.giftPrice = val
    },
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        giftName: null,
        giftPrice: null,
        picture: null,
        svgaurl: null,
        sort: null,
        status: 0,
        createTime: null,
        remark: null
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getGift({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateGift(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addGift(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
